import React, { useCallback, useState, VFC } from 'react';
import styled, { css } from 'styled-components';
import ContentContainer from './ContentContainer';
import { Swiper as SwiperClass } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';

import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';

import Button from './StartNowButton';

interface CaseStudiesProps {
  onStartBtnClick: () => void;
  startBtnTitle?: string;
  title: string;
  slides: {
    user: { name: string; img: string };
    img: string;
    imgMobile?: string;
    alt?: string;
  }[];
  bgHex?: string;
  flexDirection?: string;
  alignItems?: string;
  infoStyle?: boolean;
}

const CaseStudies: VFC<CaseStudiesProps> = ({
  onStartBtnClick,
  startBtnTitle,
  title,
  slides,
  bgHex,
  flexDirection,
  alignItems,
  infoStyle,
}) => {
  const [activeReviewsSlideIndex, setActiveReviewsSlideIndex] = useState(0);
  const [reviewsSwiper, setReviewsSwiper] = useState<SwiperClass>();
  const [usersSwiper, setUsersSwiper] = useState<SwiperClass>();

  const handleUserClick = useCallback(
    (index: number) => () => {
      setActiveReviewsSlideIndex(index);
      reviewsSwiper?.slideTo(index);
    },
    [setActiveReviewsSlideIndex, reviewsSwiper],
  );

  const handleReviewsSlideChange = useCallback(
    (swiperInstance: SwiperClass) => {
      setActiveReviewsSlideIndex(swiperInstance.activeIndex);
      usersSwiper?.slideTo(swiperInstance.activeIndex);
    },
    [setActiveReviewsSlideIndex, usersSwiper],
  );

  return (
    <Container bgHex={bgHex} infoStyle={infoStyle}>
      <Content flexDirection={flexDirection} alignItems={alignItems}>
        <div>
          <Title>{title}</Title>
          <UsersSwiperStyled
            onSwiper={setUsersSwiper}
            slidesPerView="auto"
            effect="slide"
            allowTouchMove
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index} onClick={handleUserClick(index)}>
                <UserIconDynamic
                  src={slide.user.img}
                  alt=""
                  isActive={index === activeReviewsSlideIndex}
                />
                {/* <UserIcon
                  img={slide.user.img}
                  isActive={index === activeReviewsSlideIndex}
                /> */}
                <Text
                  type="buttonTextM"
                  color={
                    index === activeReviewsSlideIndex ? 'dark100' : 'dark40'
                  }
                >
                  {slide.user.name}
                </Text>
              </SwiperSlide>
            ))}
          </UsersSwiperStyled>
          {!!startBtnTitle && (
            <ButtonStyled onClick={onStartBtnClick}>
              {startBtnTitle}
            </ButtonStyled>
          )}
        </div>
        <ReviewsSwiperStyled
          onActiveIndexChange={handleReviewsSlideChange}
          onSwiper={setReviewsSwiper}
          allowTouchMove
          spaceBetween={16}
          effect="slide"
          // infoStyle={infoStyle}
        >
          {slides.map((it, index) => (
            <SwiperSlide key={index}>
              <DynamicImage
                src={it.img}
                mobile_src={it.imgMobile}
                alt={it.alt}
              />
            </SwiperSlide>
          ))}
        </ReviewsSwiperStyled>
        {!!startBtnTitle && (
          <MobileButtonStyled onClick={onStartBtnClick}>
            {startBtnTitle}
          </MobileButtonStyled>
        )}
      </Content>
    </Container>
  );
};

export default CaseStudies;

const UsersSwiperStyled = styled(Swiper)`
  margin: 3rem 0 2rem 0;

  .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .swiper-slide {
    width: 2.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 1rem 16px 0;
  }

  @media ${tablet} {
    position: absolute;
    left: 1rem;
    width: calc(100vw - 1rem);
    margin: 2rem 0 1.5rem 0;

    .swiper-wrapper {
      flex-wrap: nowrap;
    }
  }
`;

const UserIconDynamic = styled(DynamicImage)<{
  isActive: boolean;
}>`
  width: 100%;
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: 0.5rem;
  border-radius: 6.25rem;
  border: 0.0625rem solid;
  ${({ isActive, theme }) =>
    isActive
      ? css`
          border-color: ${theme.colors.orange100};
        `
      : css`
          border-color: ${theme.colors.light60};
        `}

  background-size: contain;
`;

const UserIcon = styled.div<{ isActive: boolean; img: string }>`
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: 0.5rem;
  border-radius: 6.25rem;
  border: 0.0625rem solid;

  ${({ img, isActive, theme }) =>
    isActive
      ? css`
          border-color: ${theme.colors.orange100};
        `
      : css`
          background-image: url(${img});
          border-color: ${theme.colors.light60};
        `}

  background-size: contain;
`;
const Container = styled.div<{ bgHex?: string; infoStyle?: boolean }>`
  background-color: ${({ theme, bgHex }) =>
    bgHex ? bgHex : theme.colors.lightGreen};
  padding: 4rem 0;

  @media ${tablet} {
    padding: ${props => (props.infoStyle ? '0 0 3rem 0' : '3rem 0')};
  }
`;

const Content = styled(ContentContainer)<{
  flexDirection?: string;
  alignItems?: string;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;

  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};

  align-items: ${props => (props.alignItems ? props.alignItems : 'none')};

  @media ${tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const MobileButtonStyled = styled(Button)`
  display: none;
  margin-top: 2rem;

  @media ${tablet} {
    display: block;
  }
`;

const ButtonStyled = styled(Button)`
  display: block;

  @media ${tablet} {
    display: none;
  }
`;

const ReviewsSwiperStyled = styled(Swiper)<{ infoStyle?: boolean }>`
  max-width: 32.625rem;
  width: 100%;
  margin: 0;
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadows.cardRaised};

  .swiper-slide {
    height: unset;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.shadows.cardRaised};

    img {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
    }
  }

  @media ${tablet} {
    margin-top: ${props => (props.infoStyle ? '1rem' : '7.875rem')};
  }
`;

const Title = styled(Text).attrs({
  type: 'h1',
})`
  font-size: 2.75rem;
  line-height: 130%;
  letter-spacing: -0.036em;

  @media ${tablet} {
    text-align: center;
  }

  @media ${mobile} {
    font-size: 1.625rem;
  }
`;
